import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { assetNames } from '../utilities.js/constants'

import './base.css'
import OtherHero from '../components/other_hero/other_hero'
import Layout from '../components/website_layout/layout'
import PageSections from '../components/page_sections/page_sections'

// Prevent Gatsby SSR build errors
const isBrowser = typeof window !== 'undefined'

const DentalPage = (props) => {
    const assets = assetNames.reduce((res, assetName) => {
        res[assetName] =
            props.data[assetName].gatsbyImageData ||
            props.data[assetName].buttonImage.gatsbyImageData
        return res
    }, {})

    useEffect(() => {
        if (isBrowser) {
            // Track seeing this page
            window.analytics.track('website_page_viewed', {
                page: 'foundation_dental',
            })
        }
    }, [])

    // Fixup title and meta
    const seoData = {
        ...props.data.contentfulSiteMetadata,
        title: props.data.contentfulFoundationPage.titleTag,
        description: props.data.contentfulFoundationPage.metaTag,
    }

    return (
        <>
            <Layout
                data={props.data.contentfulFoundationPage}
                seo={seoData}
                assets={assets}
                showScrollToTop={true}
            >
                <OtherHero
                    data={props.data.contentfulFoundationPage.mainContent}
                    assets={assets}
                    showAppLinks={true}
                />
                <PageSections
                    sections={props.data.contentfulFoundationPage.sections}
                    assets={assets}
                ></PageSections>
            </Layout>
        </>
    )
}

export default DentalPage

export const pageQuery = graphql`
  query UseCasePageQuery {
    contentfulFoundationPage(contentful_id: { eq: "2Qd2ER30kquxA6JQ2biJQo" }) {
      titleTag
      metaTag
      topNavigationBar {
        navPaths {
          ... on ContentfulDropDown {
            internal {
              type
            }
            name
            linkEntries {
              label
              url
            }
          }
          ... on ContentfulPath {
            internal {
              type
            }
            url
            label
          }
        }
        logolarge {
          file {
            url
          }
        }
      }
      mainContent {
        successMessage
        errorMessage
        rightImage {
          gatsbyImageData(width: 800)
          title
        }
        subtitle
        topBubbleText
        title
        fieldPlaceholder
        fieldButton {
          label
          url
        }
        formTitle
      }
      sections {
        ... on ContentfulGenericTwoTitleWithCtaRightImage {
          internal {
            type
          }
          heading
          subHeading
          paragraphText {
            paragraphText
          }
          imageRight {
            title
            gatsbyImageData
          }
          ctaLink {
            label
            url
          }
          ctaButtons {
            storeButtons {
              buttonImage {
                title
                gatsbyImageData
              }
              buttonLink {
                label
                url
              }
            }
          }
        }
        ... on ContentfulHowItWorksBoth {
          internal {
            type
          }
          heading
          subHeading
          leftPillHeading
          leftSubSubHeading
          leftImageAlt
          leftImage {
            title
            gatsbyImageData
          }
          leftParagraphText {
            leftParagraphText
          }
          rightPillHeading
          rightSubSubHeading
          rightImageAlt
          rightImage {
            title
            gatsbyImageData
          }
          rightParagraphText {
            rightParagraphText
          }
        }
        ... on ContentfulGenericOneTitleLeftImage {
          internal {
            type
          }
          leftImageAlt
          leftImage {
            gatsbyImageData
          }
          heading
          paragraphText {
            paragraphText
          }
        }
        ... on ContentfulTestimonialContent {
          internal {
            type
          }
          title
          item {
            rating
            title
            username
            paragraph {
              raw
            }
          }
        }
        ... on ContentfulFaqContent {
          internal {
            type
          }
          section {
            subtitle
            title
            image {
              gatsbyImageData(width: 80)
              title
            }
            question {
              question
              answer {
                raw
                references {
                  ... on ContentfulAsset {
                    contentful_id
                    __typename
                    gatsbyImageData
                    title
                  }
                }
              }
              questionID
            }
          }
        }
        ... on ContentfulExtendedEligibility {
          internal {
            type
          }
          heading
          subHeading1
          subHeading2
          eligibilityHints
          requirements
          bulletImage1 {
            title
            gatsbyImageData
          }
          bulletImage2 {
            title
            gatsbyImageData
          }
          rightImage {
            gatsbyImageData
            title
          }
          paragraphText1 {
            paragraphText1
          }
          paragraphText2 {
            paragraphText2
          }
        }
        ... on ContentfulDownloadCtaSimple {
          internal {
            type
          }
          heading
          paragraphText {
            paragraphText
          }
          imageAlt
          rightImage {
            gatsbyImageData
          }
        }
      }
      footerCta {
        successMessage
        errorMessage
        subtitle
        title
        placeholderTitle
        placeholderFieldName
        ctaTitle
        image {
          gatsbyImageData(width: 396)
          title
        }
      }
      footerContent: footer {
        section1Title
        section2Title
        section3Title
        title
        section1Paths {
          newTab
          label
          url
          logo {
            gatsbyImageData(width: 80)
            title
          }
        }
        section2Paths {
          newTab
          label
          url
          logo {
            gatsbyImageData(width: 80)
            title
          }
        }
        section3Paths {
          newTab
          label
          url
          logo {
            gatsbyImageData(width: 80)
            title
          }
        }
      }
    }
    googlePlayBadgeBlack: contentfulCtaButton(
      contentful_id: { eq: "q00MfpdmihX36ZSSzO2VS" }
    ) {
      buttonAlt
      buttonImage {
        gatsbyImageData(width: 235)
      }
    }
    appStoreBadgeBlack: contentfulCtaButton(
      contentful_id: { eq: "4JX8m4WjDpVydFskT6GEeu" }
    ) {
      buttonAlt
      buttonImage {
        gatsbyImageData(width: 235)
      }
    }
    googlePlayBadge: contentfulAsset(
      file: { fileName: { eq: "google-store-badge-new.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    appStoreBadge: contentfulAsset(
      file: { fileName: { eq: "app-store-badge-new.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    appStoreBadgeWhite: contentfulCtaButton(
      contentful_id: { eq: "3ZS7p0m8M9eUOrTtdDqaVq" }
    ) {
      buttonAlt
      buttonImage {
        gatsbyImageData(width: 235)
      }
    }
    googlePlayBadgeWhite: contentfulCtaButton(
      contentful_id: { eq: "3EDtN4btty8Ieb3oGqCbaW" }
    ) {
      buttonAlt
      buttonImage {
        gatsbyImageData(width: 235)
      }
    }
    browserBadge: contentfulAsset(
      file: { fileName: { eq: "browser-badge.png" } }
    ) {
      gatsbyImageData(width: 235)
    }
    contentfulSiteMetadata(contentful_id: { eq: "4GzU8oQlPc2YDh3dwyPU1N" }) {
      description
      imageUrl
      siteName
      title
      twitterHandle
      siteUrl
    }
  }
`
